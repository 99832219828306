import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Image } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/lutein-first-aid-header.jpg"
import LuteinComplexTablet from "./../../images/lutein-first-aid-header.jpg"
import LuteinComplexDesktop from "./../../images/lutein-first-aid-header.jpg"
import LuteinComplexXL from "./../../images/lutein-first-aid-header.jpg"

import DrAtanasova from "./../../images/lut-virusi-d-r-atanasova.jpg"

import EyeExam from "./../../images/lutein-eye-exam.jpg"
import lutShield from "./../../images/lutein-shield.jpg"

import capsule from "./../../images/lut-virusi-capsule.png"
import lutein from "./../../images/lut-virusi-lutein-complex.jpg"


const LuteinPurvaPomosht = () => (
  <Layout backButton={true} pageInfo={{ pageName: "lutein-purva-pomosht" }}>
    <Seo title="Първа помощ за очите" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>ПЪРВА ПОМОЩ ЗА ОЧИТЕ</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Първа помощ за очите"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Първа помощ за очите
          </h1>
          <h2 className="font-light">
            <em>Има начин да виждаме по-добре</em>
          </h2>
        </Col>
        <Col sm={12}>
          <p>
            Докато дните минават и се превръщат в години, тежки завеси се
            спускат пред очите: зрението отслабва, а цветовете и обектите губят
            своите очертания. Специалистите обаче са категорични – има начин да
            виждаме по-добре, но е нужна ежедневна подкрепа с доказани ефекти.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={12}>
          <h4>„Експертът“ в очното здраве</h4>
          <p>
            Експертите в областта разкриват, че доказано ефективна в борбата със
            стареенето на очите е специална маслена разработка, събрала в себе
            си силата на естествените природни съставки. Позната у нас под името
            ЛУТЕИН КОМПЛЕКС ПЛЮС, тя е подпомогнала зрението на хиляди хора в
            България и по света. Специалната формула смело може да бъде наречена
            „Експерт“ в очното здраве, заради комбинацията на важните за
            зрението лутеин, зеаксантин и екстракт от черна боровинка плюс
            оптималните количества цинк, витамин С и витамин Е. Те подпомагат
            работата на очите и предпазвайки ги от вредните външни влияния,
            оказват двойно действие.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={8}>
          <h4>Понижава рисковете</h4>
          <p>
            Подсилвайки защитната бариера на очите срещу лоши бактерии и вируси,
            ЛУТЕИН КОМПЛЕКС ПЛЮС се оказва особено ценен в ситуации, свързани с
            вредни външни фактори. Защитени отвън, очите вече разчитат на
            подкрепа отвътре. Тук идва силата на съставките, жизнено важни не
            само за зрението, а и за здравето на цялото тяло: те понижават
            успешно рисковете от увреждането на части от окото и доказано
            помагат за забавяне на развитието на тежки очни заболявания.
          </p>
        </Col>
        <Col sm={4}>
          <Image className="w-100 float-right" src={lutShield} />
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={4}>
          <Image className="w-100" src={EyeExam} fluid />
        </Col>
        <Col>
          <h4>Подсилва зрението</h4>

          <p>
            Специалистите зад граница прилагат успешно формулата при едно от
            най-разпространените състояния сред възрастните хора – замъглено
            зрение. Наблюденията им сочат, че с помощта на ЛУТЕИН КОМПЛЕКС ПЛЮС
            то отново може да стане остро и ясно независимо от годините. Умората
            и напрежението в очите, също се повлияват отлично, подобрява се дори
            виждането при недостатъчна светлина и през нощта. Сухотата и
            напрежението в очите, вследствие на прекомерно стоене пред компютър,
            също са сред „мишените“ на ЛУТЕИН КОМПЛЕКС ПЛЮС.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <h4>Висока ефективност</h4>
          <Image className="w-25 float-right mb-5 mt-5 ml-4" src={capsule} />
          <p>
            Тайната на изключително високата ефективност на{" "}
            <Link to="/lutein-complex">ЛУТЕИН КОМПЛЕКС ПЛЮС</Link> се корени не
            само в съставките, но и в специалната технология на производството –
            LICAPS™. С нейна помощ всички полезни и естествени вещества са в
            маслена форма и запазват в максимална степен своята биологична
            активност. Така например една капсула ЛУТЕИН КОМПЛЕКС ПЛЮС се
            разтваря за приблизително 10 минути, а тялото усвоява над 90% от
            съдържащите се в нея полезни вещества.
          </p>
          <p>
            Благодарение на всички тези предимства ЛУТЕИН КОМПЛЕКС ПЛЮС работи
            не само по-бързо, но е и далеч по-ефективен в грижата за доброто
            зрение, което ни е необходимо, за да се чувстваме пълноценни хора
            независимо от възрастта.
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={3}>
          <Image className="w-100 mb-4" src={DrAtanasova} fluid />
        </Col>
        <Col sm={9}>
          <h5>
            <strong>Уверих се, че Лутеин Комплекс Плюс помага при перде</strong>
          </h5>
          <p>
            „Препоръчвам ЛУТЕИН КОМПЛЕКС ПЛЮС, тъй като той е съвкупност от
            най-добрите съставки и екстракти за грижата на очите във високи
            концентрации. Проучванията сочат, че те намаляват риска от замъглено
            виждане и укрепват кръвоносните съдове на очите. Приемайте ЛУТЕИН
            КОМПЛЕКС ПЛЮС по 1 капсула дневно поне 3 месеца. На лицата в риск от
            развитие на очни проблеми, както и на страдащите от такива,
            препоръчвам да провеждат ежегодно поне по един тримесечен
            терапевтичен курс с прием на ЛУТЕИН КОМПЛЕКС ПЛЮС.“
          </p>
        </Col>
        <Col>
          <p>
            <i>
              Д-р Василка Атанасова, офталмолог, гр. Пловдив
            </i>
          </p>
        </Col>
      </Row>

      <Row className="product-container">
        <Col md={{ span: 8, offset: 2 }}>
          <Link to="/lutein-complex">
            <Image className="w-100" src={lutein} />
          </Link>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>
            Можете да откриете ЛУТЕИН КОМПЛЕКС ПЛЮС в най-близката до вас
            аптека.
          </p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/lutein-complex">
              Повече информация за ЛУТЕИН КОМПЛЕКС ПЛЮС можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            *Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default LuteinPurvaPomosht
